import { useKonvaScale } from 'hooks/useKonvaScale';
import { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-konva';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export default function HullCoords() {
	const [showLines, setShowLines] = useState(false);
	const { allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const scale= useKonvaScale();

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if ((event.key === 'r' || event.key === 'R')) {
				event.preventDefault();
				setShowLines(true);
			}
		};

		const handleKeyUp = (event: KeyboardEvent) => {
			setShowLines(false);
		};

		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, []);

	const transformHullCoords = useCallback((segment: RasterRoofSegment) => {
		return segment.hullCoords?.flat()?.map((point, index) => index % 2 === 0 ? point * scale.x : point * scale.y);
	},[scale]);

	if (!showLines) {
		return null;
	}

	return (
		<>
			{
				allRoofSegs.map(eachSeg => (
					(<Line
						key={eachSeg.id}
						stroke={'#66FF00'}
						strokeWidth={2}
						points={transformHullCoords(eachSeg)}
					/>))
				)
			}
		</>
	);
}