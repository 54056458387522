import React, { useEffect, useRef } from 'react';
import { useRefDispatch, useRefs } from 'contexts/RefContext';
import RasterImage from './RasterImage';
import { Group } from 'react-konva';
import { Circle } from 'konva/lib/shapes/Circle';
import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import { setAzimuthOrPitchForNewlyCreatedNrelFacets, setNewlyCreatedFacets, setUnfinalizedFacet } from 'store/slices/ToolSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { KonvaEventObject } from 'konva/lib/Node';
import { setDropdownState } from 'store/slices/EditFacetModalSlice';
import { calculateCentroidOfPolygon, calculateArrowPoints } from '../util';
import { DESIGN_MODES, KONVA_FACET_GROUP, SEMI_TRANPARENT_BLACK_PANEL } from '../../../constants';

const distance = (a: number[], b: number[]) => Math.sqrt((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2);

const lineConfig = {
	stroke: 'rgba(0,0,0,0.4)',
	strokeWidth: 3,
	fill: 'rgba(0,0,0,0.4)',
};

type DrawArrowProps = {
	polPoints: number[][];
	center: Vector2d;
	userFacetGroup: Konva.Group;
	konvaRef: React.RefObject<Konva.Stage>;
	dispatchCallback: (id: string, azimuth: number) => void,
};

type ArrowData = {
  angle: number;
  groupRefId: string;
  selected: boolean;
  center: Konva.Vector2d;
};
// Function to draw arrows with updated types
function DrawArrows({ polPoints, center, userFacetGroup, konvaRef, dispatchCallback }: DrawArrowProps) {
	// Function to create an arrow
	const createArrow = (points: number[], customData: ArrowData, rectConfig: Konva.RectConfig): Konva.Group =>{
		const rect = new Konva.Rect({
			...rectConfig,
			visible: true,
			stroke: SEMI_TRANPARENT_BLACK_PANEL,
			strokeWidth: 1,
			fill: SEMI_TRANPARENT_BLACK_PANEL,
			cornerRadius: [0, 2, 2, 0]
		});

		const arrow =new Konva.Arrow({
			points,
			pointerLength: 4,
			pointerWidth: 4,
			fill: 'white',
			stroke: 'white',
			strokeWidth: 1,
			hitStrokeWidth: 20,
		});

		const group = new Konva.Group({customData, name: 'azimuth_selector'});
		group.add(rect);
		group.add(arrow);	

		return group;
	};

	// Function to handle arrow click event
	const handleArrowClick = (arrowWithRect: Konva.Group, data: ArrowData) => {
		arrowWithRect.on('click', (e) => {
			e.cancelBubble = true;
			const { angle, groupRefId } = data;
			dispatchCallback(groupRefId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0], angle);
			selectArrow(arrowWithRect, userFacetGroup);
		});

		arrowWithRect.on('mouseenter', () => {
			if(arrowWithRect.attrs?.customData?.selected) return;
			toggleArrowHighlight(arrowWithRect, true);
		});

		arrowWithRect.on('mouseleave', () => {
			if(arrowWithRect?.attrs?.customData?.selected) return;
			toggleArrowHighlight(arrowWithRect, false);
		});
	};

	// Function to select arrow and handle visibility
	const selectArrow = (arrowWithRect: Konva.Group, group: Konva.Group) => {
		const data = arrowWithRect.getAttr('customData') as ArrowData;
		arrowWithRect.setAttr('customData', { ...data, selected: true, });
		toggleArrowHighlight(arrowWithRect, true);

		group.children?.forEach((child) => {
			if (child.getClassName() === 'Group' && child.hasName('azimuth_selector') && child._id !== arrowWithRect._id) {
				const childData = child.getAttr('customData') as ArrowData;
				if (childData?.selected) {
					child.setAttr('customData', { ...childData, selected: false });
					toggleArrowHighlight(child as Konva.Group, false);
				}
			}
		});
	};

	//Function to toggle Highlight of arrows
	const toggleArrowHighlight = (arrowWithRect: Konva.Group, highlight = false) => {
		const rectColor = highlight ? '#ffc005' : SEMI_TRANPARENT_BLACK_PANEL;
		const arrowColor = highlight ? 'black' : 'white';
		if(!arrowWithRect.getChildren()?.length) return;
		const [rect, arrow] = arrowWithRect.getChildren() as [Konva.Rect, Konva.Arrow];
		rect.stroke(rectColor);
		rect.fill(rectColor);
		arrow.stroke(arrowColor);
		arrow.fill(arrowColor);
	};

	// Iterate through points and create arrows
	for (let i = 0; i < polPoints.length; i++) {
		const j = (i + 1) % polPoints.length;
		const { arrow: ap, rectangle } = calculateArrowPoints([polPoints[i], polPoints[j]], center);

		const arrowData: ArrowData = {
			angle: ap.angle,
			groupRefId: userFacetGroup.id(),
			selected: false,
			center,
		};

		const arrowWithRect = createArrow([...ap.outwardPoints[0], ...ap.outwardPoints[1]], arrowData, rectangle);

		// Handle the first arrow as the center arrow
		if (i === 0) {
			const { angle, groupRefId } = arrowData;
			dispatchCallback(groupRefId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0], angle);
			selectArrow(arrowWithRect, userFacetGroup); // This ensures the first arrow is selected and blue
		}

		handleArrowClick(arrowWithRect, arrowData);
		userFacetGroup.add(arrowWithRect);
	}
}

type Props = {
	showAzimuthArrows: boolean;
}
export default function PanelDrawer(props : Props) {
	const { editModeEnabled, drawModeEnabled, moveStage, newlyCreatedFacets, 
		groundMountEnabled, noGapGroundMountEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { mode } = useSelector((state: RootState) => state.roofData.data);
	const { konvaRef } = useRefs();
	const refDispatch = useRefDispatch();
	const groupRef = useRef<Konva.Group | null>(null);
	const userFacetGroup = useRef<Konva.Group | null>(null);
	const pointsRef = useRef<number[][]>([]);
	const lineRef = useRef<Konva.Line>(new Konva.Line(lineConfig));
	const pointLineRef = useRef<Konva.Line>(new Konva.Line(lineConfig));
	const isDrawing = useRef<boolean>(false);
	const longTouchTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const dispatch = useDispatch<AppDispatch>();
	const canDrawFacet = editModeEnabled && drawModeEnabled && !moveStage;

	// const panelRef = useRef<Konva.Rect | null>(null);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if ((event.ctrlKey && event.key === 'z') || event.key === 'Escape') {
				deleteLastLine();
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, []);

	useEffect(() => {
		refDispatch({ type: 'ADD_LAST_POLYGON_REF', payload: { ref: {
			groupRef:null,
			pointsRef:null,
			lineRef:null,
			pointLineRef:null,
		}} });
	}, [drawModeEnabled, refDispatch]);

	function deleteLastLine() {
		if (!pointsRef.current.length) return;

		const children = userFacetGroup.current?.children;
		pointLineRef.current.points([]);

		if (children && children.length > 1) {
			const lastChild = children[children.length - 1];
			if (lastChild instanceof Konva.Image || lastChild instanceof Konva.Line) return;
			lastChild.destroy();
		}

		const points = lineRef.current.points();
		if (points.length >= 2) {
			points.splice(-2);
			lineRef.current.points(points);
		}
		if (pointsRef.current.length > 0) {
			pointsRef.current.splice(-1);
		}
	}

	function dispatchNewlyCreatedFacets(facetPoints: number[][]) {
		dispatch(setNewlyCreatedFacets({
			newlyCreatedFacet: {
				hullCoords: facetPoints,
				konvaPolygonId: lineRef.current.id().split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0],
				isGroundMount: groundMountEnabled,
				noGapGroundMount: noGapGroundMountEnabled, 
			}
		}));
	}

	function makeOtherPolygonsInvisbleWhileUserIsDrawing(opacity: number) {
		groupRef.current?.children?.forEach(c => {
			if (c instanceof Konva.Image) return;
			c.opacity(opacity);
		});
	}

	function makePolygon() {
		if (!konvaRef?.current) return;
		const { x, y } = konvaRef.current.getRelativePointerPosition() as Vector2d;

		// it only has img
		if (groupRef.current?.children?.length === 1) {
			pointsRef.current = [];
			isDrawing.current = false;
			const uuid = crypto.randomUUID();
			userFacetGroup.current = new Konva.Group({
				id: uuid + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME
			});
			pointLineRef.current = new Konva.Line(lineConfig);
			lineRef.current?.id(uuid + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.LINE_ID_SUFFIX);
		}
		// 
		if (pointsRef.current.length === 0 && !isDrawing.current) {
			// makeOtherPolygonsInvisbleWhileUserIsDrawing(0.2);
			groupRef.current?.add(userFacetGroup.current as Konva.Group);
			userFacetGroup.current?.add(lineRef.current);
			userFacetGroup.current?.add(pointLineRef.current);
			// groupRef.current?.add(lineRef.current);
			// groupRef.current?.add(pointLineRef.current);
		}
		if (pointsRef.current.length > 2 && distance([x, y], pointsRef.current[0]) < 10) {
			lineRef.current.closed(true);
			pointLineRef.current.remove();
			const pts = lineRef.current?.points();

			const p: number[][] = [];
			pts.forEach((v, i) => {
				const ind = Math.floor(i / 2);
				if (i % 2 === 0) p.push([v]);
				else p[ind].push(v);
			});
			makeOtherPolygonsInvisbleWhileUserIsDrawing(1);
			dispatchNewlyCreatedFacets(p);
			if(props.showAzimuthArrows) {
				DrawArrows({
					polPoints: p,
					center: calculateCentroidOfPolygon(pts),
					userFacetGroup: userFacetGroup.current!,
					konvaRef,
					dispatchCallback: (id, azimuth) => {
						dispatch(setAzimuthOrPitchForNewlyCreatedNrelFacets({
							facetId: id,
							azimuthDegrees: azimuth,
						}));
					}
				});
			}
			dispatch(setUnfinalizedFacet({id: lineRef.current.id().split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0]}));
			// setInitialAzimuthForSegment(p, lineRef.current._id);
			pointsRef.current = [];
			const id = crypto.randomUUID();
			lineRef.current = new Konva.Line(
				{
					...lineConfig, id: id + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.LINE_ID_SUFFIX
				});
			pointLineRef.current = new Konva.Line(lineConfig);
			userFacetGroup.current = new Konva.Group({
				id: id + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME
			});
			isDrawing.current = false;
			return;
		}

		pointsRef.current.push([+x, +y]);
		addPoint(x, y);
		lineRef.current.points(pointsRef.current.flat());
		lineRef.current.on('contextmenu', handlePolygonClick);
		lineRef.current.on('touchstart', handleTouchStart);
		lineRef.current.on('touchend', handleTouchEndOrCancel);
		refDispatch({ type: 'ADD_LAST_POLYGON_REF', payload: { ref: {
			groupRef: userFacetGroup,
			pointsRef,
			lineRef,
			pointLineRef,
		}} });
	}

	function addPoint(x: number, y: number) {
		userFacetGroup.current?.add(new Circle({
			x: x,
			y: y,
			radius: 3,
			fill: 'blue',
			stroke: 'black',
			strokeWidth: 1
		}));
		isDrawing.current = true;
	}

	function handleMouseMove() {
		if (!editModeEnabled || !drawModeEnabled || !konvaRef?.current || !pointsRef.current.length) return;
		const { x, y } = konvaRef.current.getRelativePointerPosition() as Vector2d;
		const points = pointsRef.current;
		const [startX, starty] = points[points.length - 1];
		pointLineRef.current.points([startX, starty, x, y]);
	}

	function handlePolygonClick(e: KonvaEventObject<MouseEvent>) {
		if (!editModeEnabled || !drawModeEnabled) return;
		e.evt.preventDefault();
		const lineId = e.target.getAttr('id');
		console.log('target id', lineId);
		dispatch(setUnfinalizedFacet({ id: lineId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0] }));
		dispatch(setDropdownState({ open: true, position: { x: e.evt.clientX, y: e.evt.clientY } }));
	}

	function handleTouchStart(e: KonvaEventObject<TouchEvent>) {
		if (!editModeEnabled || !drawModeEnabled) return;
		clearTimeout(longTouchTimerRef.current);
		longTouchTimerRef.current = setTimeout(() => {
			const lineId = e.target.getAttr('id');
			console.log('target id', lineId);
			dispatch(setUnfinalizedFacet({ id: lineId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0] }));
			dispatch(setDropdownState({ open: true, position: { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY } }));
		}, 2000);
	}

	function handleTouchEndOrCancel() {
		clearTimeout(longTouchTimerRef.current);
	}
	const totalCreateFacets = Object.keys(newlyCreatedFacets);

	return (

		<Group
			ref={groupRef}
			name={'panel-drawer'}
			onClick={(e) => {

				if (!canDrawFacet) return;
				if (mode !== DESIGN_MODES.DEFAULT && totalCreateFacets.length >= 1) {
					return;
				}
				if (e.evt.button === 0) {
					makePolygon();
				}
			}}
			onMouseMove={handleMouseMove}
		>
			<RasterImage />
		</Group>
	);
}

