/* eslint-disable max-len */
import { toggleDeleteModalState } from 'store/slices/DeleteModalSlice';
import { CloseBtn, DropdownDiv } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { setDropdownState, toggleRecalculateLayoutModalState, toggleShadingModalState, toggleUndoShadingModalState } from 'store/slices/EditFacetModalSlice';
import { Popup } from 'reactjs-popup';
import { setUnfinalizedFacet } from 'store/slices/ToolSlice';
import { toggleOrientationModalState } from 'store/slices/ChangeOrientationModalSlice';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import Button from 'components/Basic/Button';
import { toggleManualToolModalState } from 'store/slices/ManualToolModal';
import { useRef } from 'react';
import { DESIGN_MODES } from '../../../../constants/index';

interface Option {
	label: string;
	action: () => AnyAction;
	icon: JSX.Element;
}

export default function EditFacetModal() {
	const { position, open } = useSelector((state: RootState) => state.editFacetModal.data);
	const { drawModeEnabled, selectedRoofIndex , roofIndexes } = useSelector((state: RootState) => state.toolNewPostions.data);

	const popupRef= useRef<HTMLDivElement| null>(null);
	const { allRoofSegs, mode } = useSelector((state: RootState) => state.roofData.data);
	const { canRemoveShading } = useSelector((state: RootState) => state.QueryParams.data);
	const facet = allRoofSegs.find(rs=>rs.id===selectedRoofIndex);
	const queriesRunningInBackGround = useQueriesRunningInBackGround();
	const defaultMode = mode == DESIGN_MODES.DEFAULT;
	const isGroundMount = !!facet?.isGroundMount;
	const canRemoveShadingEnabled = defaultMode && canRemoveShading && !isGroundMount;

	const dispatch = useDispatch();

	const Options: Option[] = [];

	if (!drawModeEnabled) {
		if(!(roofIndexes.length > 0)){
			Options.push({
				label: 'Change orientation',
				action: () =>
					toggleOrientationModalState({ open: true, isLoading: false }),
				icon: (
					<svg
						width='20px'
						style={{ marginRight: '5px' }}
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path d="M19.0729 8.25146C19.0729 4.97901 16.3954 2.00405 12.528 2.00405L14.3129 4.08652M1 11.8214C1 15.0939 3.67746 18.0688 7.54491 18.0688L5.75993 15.9864M6.94992 1L18.7754 12.8255L12.8255 18.7754L1 6.94992L6.94992 1Z" stroke="#4A525A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
				),
			});
		}

		if (canRemoveShadingEnabled) {
			Options.push({
				label: `${!facet?.isFluxPatched ? 'Remove shading' : 'Undo Remove shading'}`,
				action: () => toggleShadingModalState({ value: true, type: `${!facet?.isFluxPatched ? 'remove' : 'undo'}` }),
				icon: (
					!facet?.isFluxPatched ? 
						<svg style={{ marginRight: '5px' }} width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.6516 10.8899C15.3838 15.0396 10.7459 16.7483 8.67114 14.1852C5.25364 17.1144 1.83623 14.0632 2.56862 10.8899C1.10916 9.30868 0.684579 7.363 1.22626 5.88582C1.77166 4.39847 3.29466 3.38443 5.13168 3.20073C6.59639 0.515506 10.0138 0.027472 12.2106 3.20073C13.824 3.07174 15.5659 4.4586 16.1163 6.25215C16.6069 7.85059 16.0895 9.79718 14.6516 10.8899Z" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M8.67046 6.13123V10.0368M8.67046 20.045V12.5999M8.67046 10.0368L11.4776 8.32813M8.67046 10.0368V12.5999M8.67046 12.5999L5.98535 11.0132" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
						:
						<svg style={{ marginRight: '5px' }} width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.6516 10.8899C15.3838 15.0396 10.7459 16.7483 8.67114 14.1852C5.25364 17.1144 1.83623 14.0632 2.56862 10.8899C1.10916 9.30868 0.684579 7.363 1.22626 5.88582C1.77166 4.39847 3.29466 3.38443 5.13168 3.20073C6.59639 0.515506 10.0138 0.027472 12.2106 3.20073C13.824 3.07174 15.5659 4.4586 16.1163 6.25215C16.6069 7.85059 16.0895 9.79718 14.6516 10.8899Z" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M8.67046 6.13123V10.0368M8.67046 20.045V12.5999M8.67046 10.0368L11.4776 8.32813M8.67046 10.0368V12.5999M8.67046 12.5999L5.98535 11.0132" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
				),
			});
		}

		Options.push(
			{
				label: 'Recalculate Layout',
				action: () => toggleRecalculateLayoutModalState({ value: true }),
				icon: (
					<svg width="20" height="20" style={{ marginRight: '5px' }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M14.61 19C17.0346 19 19.0002 17.0344 19.0002 14.6097C19.0002 12.1851 17.0346 10.2195 14.61 10.2195C12.1853 10.2195 10.2197 12.1851 10.2197 14.6097C10.2197 17.0344 12.1853 19 14.61 19Z" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M15.6638 12.6342L16.0655 13.1478C16.2968 13.4433 16.2542 13.532 15.882 13.532H13.5564C12.9146 13.532 12.854 13.8691 12.854 14.4302M13.5564 16.5854L13.1547 16.0717C12.9238 15.7763 12.966 15.6871 13.3382 15.6871H15.6638C16.3052 15.6871 16.3662 15.3504 16.3662 14.7893" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M8.90244 16.8049H4C2.34315 16.8049 1 15.4617 1 13.8049V4C1 2.34315 2.34315 1 4 1H13.8049C15.4617 1 16.8049 2.34315 16.8049 4V8.90244" stroke="#64686A" strokeWidth="1.6"/>
						<path d="M5.1582 3.19507V7.12212" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M7.12207 5.15869L3.19502 5.15869" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M6.57617 11.1499L3.79933 13.9267" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M6.57617 13.9268L3.79933 11.1499" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M14.1709 4.95728L10.2438 4.95728" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				),
			});

		if(!defaultMode || (defaultMode && facet?.isGroundMount)){
			Options.push({
				label: 'Edit Pitch and Shading',
				action: () => toggleManualToolModalState({ value: true }),
				icon: ( 
					<svg style={{ marginRight: '5px' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width="16">
						<path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
					</svg>
				
				),
			});
		}
	}

	Options.push({
		label: 'Delete Facet',
		action: () => toggleDeleteModalState({ value: true }),
		icon: (
			<svg width="18" height="21" style={{ marginRight: '5px' }} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6.9514 9.50903V15.5549M10.919 9.50903V15.5549M1 5.47845H16.8704M15.8785 5.47845L15.0185 17.7133C14.9829 18.2217 14.7589 18.6976 14.3918 19.045C14.0246 19.3923 13.5414 19.5855 13.0397 19.5855H4.83072C4.32896 19.5855 3.84583 19.3923 3.47864 19.045C3.11146 18.6976 2.8875 18.2217 2.85188 17.7133L1.9919 5.47845H15.8785ZM11.9109 5.47845V2.45552C11.9109 2.18828 11.8064 1.93198 11.6204 1.74301C11.4344 1.55404 11.1821 1.44788 10.919 1.44788H6.9514C6.68833 1.44788 6.43604 1.55404 6.25002 1.74301C6.064 1.93198 5.9595 2.18828 5.9595 2.45552V5.47845H11.9109Z" stroke="#64686A" strokeWidth="1.60573" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		),

	});

	function handleOptionClick(option: Option) {
		closeDropdwon();
		dispatch(option.action());
	}

	function closeDropdwon() {
		dispatch(setDropdownState({ open: false, position: null }));
	}

	const overlayStyle = { background: 'rgba(0,0,0,0)' };

	return (
		<Popup
			contentStyle={{
				width: 'fit-content',
				borderRadius: '4px',
				left: position?.x,
				top: position?.y,
				position: 'fixed',
				background: '#000000a1',
				border: 'none',
				display: !position ? 'none' : 'grid',
				boxShadow: '1px 0 5.3px #110e0e40'
			}}
			open={open}
			closeOnDocumentClick={true}
			onClose={closeDropdwon}
			{...{overlayStyle}}
		>
			<DropdownDiv ref={popupRef}>
				<CloseBtn style={{ marginBottom: '10px' }}>
					<svg
						style={{ color: '#fff' }}
						onClick={() => {
							dispatch(setUnfinalizedFacet({ id: null }));
							closeDropdwon();
						}}
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						stroke='currentColor'
						strokeWidth='2'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M6 18L18 6M6 6l12 12'
						></path>
					</svg>
				</CloseBtn>
				{Options.map((option) => (
					<Button
						style={{
							padding: '0.5rem 1.25rem',
							color: '#28373E',
							fontSize: '0.938rem',
							background: '#fff',
							borderColor: '#feb517',
							display: 'flex',
							alignItems: 'center',
							borderRadius: '27px',
							fontWeight: '600',
							justifyContent: 'start'
						}}
						className={option.label === 'Delete Facet' ? 'delete-fecet-fullview' : ''}
						key={option.label}
						disabled= {queriesRunningInBackGround}
						onClick={() => handleOptionClick(option)}
					>
						{option.icon}
						<span>{option.label}</span>
					</Button>
				))}
			</DropdownDiv>
		</Popup>
	);
}
