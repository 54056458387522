import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import DoneBtn from './ButtonDiv/CreateBtn';
import {
	EditToolOptionsDiv, MainDiv, ToolBarWrapper, PanelToolWrap,
	FacetPanelWrap, CalculatebtnWrap, ToolbarLabel, PanelwithOreintation,ModalBox,
	LabelAddPanels
} from './style';
import DrawBtn from './ButtonDiv/Facet/DrawBtn';
import EditToolOptions from './ButtonDiv/Facet/EditToolOptions';
import AddPanelButton from 'components/tool/Editor/ButtonDiv/Panel/AddPanelButton';
import MovePanelButton from 'components/tool/Editor/ButtonDiv/Panel/MovePanelButton';
import DeletePanelBtn from './ButtonDiv/Panel/DeletePanelBtn';
import UndoFacetButton from './ButtonDiv/Facet/UndoButton';
import UndoPanelButton from './ButtonDiv/Panel/UndoBtn';
import RedoPanelButton from './ButtonDiv/Panel/RedoBtn';
import CancelBtn from './ButtonDiv/Panel/CancelBtn';
import GroundMountToggle from './GroundMountToggle';
import SwitchOrientation from 'components/SwitchOrientation';
import NoGapGroundMountToggle from './NoGapGroundMountToggle';
// import { setAddMultiplePanel } from 'store/slices/PanelSlice';

export default function EditorToolBar() {
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { editModeEnabled, deletedRoofIndexes, drawModeEnabled, roofIndexes, deleteObstructionsMode, newlyCreatedFacets, groundMountEnabled } = 
	useSelector((state: RootState) => state.toolNewPostions.data);
	const { activePanelMode, enableAddPanel } = 	useSelector((state: RootState) => state.panelSlice.data);
	const hasUserMadeModificaiton = !!deletedRoofIndexes?.length || !!Object.keys(newlyCreatedFacets)?.length || !!roofIndexes?.length;
	const showDoneBtnIfUserHasModified = editModeEnabled && (hasUserMadeModificaiton || activePanelMode || drawModeEnabled || deleteObstructionsMode );
	const showCanelBtnInIndividualPanelFnMOde= editModeEnabled && (drawModeEnabled || deleteObstructionsMode || activePanelMode);
	const dispatch = useDispatch();
	// const handleEnableMultipanel = () => {
	// 	dispatch(setAddMultiplePanel({shouldEnable:!enableMultipleAddPanel}));
	// };
	
	if(showIrradiance){
		return null;
	}

	return (
		<MainDiv>
			<ToolBarWrapper className='toolbar-wrap'>
				<FacetPanelWrap className='toolbar-btn-wrap a'>
					<ToolbarLabel>Facets</ToolbarLabel>
					<EditToolOptionsDiv>
						<DrawBtn />
						<EditToolOptions />
						{/* {drawModeEnabled && <UndoFacetButton />} */}
					</EditToolOptionsDiv>
					{drawModeEnabled && <ModalBox>
						<GroundMountToggle />
						{
							groundMountEnabled && <NoGapGroundMountToggle/>
						}
						<UndoFacetButton />
					</ModalBox> }
				</FacetPanelWrap>
			
				<PanelToolWrap className='toolbar-btn-wrap'>
					<ToolbarLabel>Panels</ToolbarLabel>
					<EditToolOptionsDiv>
						<AddPanelButton />
						<MovePanelButton />
						<DeletePanelBtn />
						<UndoPanelButton />
						<RedoPanelButton />

					</EditToolOptionsDiv>
					{enableAddPanel && <SwitchOrientation />}
				</PanelToolWrap>
				<CalculatebtnWrap>
					{ showDoneBtnIfUserHasModified && <DoneBtn /> }
					{ showCanelBtnInIndividualPanelFnMOde && <CancelBtn /> }
					{/* {
						enableAddPanel && <Button
							onClick={handleEnableMultipanel}
							className={enableMultipleAddPanel ? 'active' : ''}
							style={{
								background: `${enableMultipleAddPanel ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
								border: '0px',
								borderRight: '1px solid #e4e4e4',
								borderLeft: '0px',
								color: '#28373E',
								fontSize: '0.938rem',
								padding: '0px .875rem 0px 1rem',
								height: '36px',
								width: 'auto',
								minWidth: '40px',
								borderRadius: '25px',
								gap: '.6125rem'
							}}
							id="multiple-panel"
						>
							<svg width="18" height="19" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect x="1" y="1" width="7.88114" height="7.60461" stroke={`${enableMultipleAddPanel ? '#003CFF' : '#64686A'}`} strokeWidth="1.6" />
								<rect x="1" y="13.3953" width="7.88114" height="7.60461" stroke={`${enableMultipleAddPanel ? '#003CFF' : '#64686A'}`} strokeWidth="1.6" />
								<rect x="11.8086" y="1.0625" width="7.88114" height="7.60461" stroke={`${enableMultipleAddPanel ? '#003CFF' : '#64686A'}`} strokeWidth="1.6" />
								<path d="M15.8262 13.3398V20.7275" stroke={`${enableMultipleAddPanel ? '#003CFF' : '#64686A'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M19.6562 17.0339L11.9999 17.0339" stroke={`${enableMultipleAddPanel ? '#003CFF' : '#64686A'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
							<LabelAddPanels style={{
								color : `${enableMultipleAddPanel ? '#003CFF' : 'var(--label)'}`
							}}>Multiple panels</LabelAddPanels>
						</Button>
					} */}
				</CalculatebtnWrap>

			</ToolBarWrapper>
		</MainDiv>
	);
}