import styled, { Interpolation } from 'styled-components';
type props = {
  styles?: Interpolation<React.CSSProperties>
	fullScreenEnabled?:boolean;
	isParentSQ?: boolean;
}
export const FlexDivRow = styled.div<props>`
	display: flex;
	flex-direction: row;
	margin-top: .2em;
	margin-bottom: 0;
	align-items:center;
	gap:.45rem;
	${({ styles }) => styles}
	// @media (max-width: 500px){
	// 	margin-bottom: 1rem;
	// }
	
	label{
		font-family: Inter;
    font-weight: 600;
    color: #4A525A;
    padding-right: 0px;
    line-height: normal;
    font-size: 0.875rem;
		position: relative;
		top: -2px;
	}
`;