import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ResetChangesModalState = {
    open: boolean;
    warningMessage: string;
		combinedObjsForNonDefaultMode: facetDetail[] | null;
		combinedObjsForDefaultMode: facetDetail[] | null;
};

const initialState: { data: ResetChangesModalState } = {
	data: {
		open: false,
		warningMessage: '',
		combinedObjsForNonDefaultMode: null,
		combinedObjsForDefaultMode: null,
	},
};

const resetChangesModalSlice = createSlice({
	name: 'resetChangesModal',
	initialState,
	reducers: {
		toggleResetChangesLayoutModalSliceState(
			state,
			action: PayloadAction<Partial<ResetChangesModalState>>
		) {
			state.data = { ...state.data, ...action.payload };
		},
	},
});

export const { toggleResetChangesLayoutModalSliceState } = resetChangesModalSlice.actions;
export default resetChangesModalSlice.reducer;
