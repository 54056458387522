import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type RecalculateLayoutModalState = {
    open: boolean;
    warningMessage: string;
};

const initialState: { data: RecalculateLayoutModalState } = {
	data: {
		open: false,
		warningMessage: '',
	},
};

const recalculateLayoutModalSlice = createSlice({
	name: 'recalculateLayoutModal',
	initialState,
	reducers: {
		toggleRecalculateLayoutModalSliceState(
			state,
			action: PayloadAction<Partial<RecalculateLayoutModalState>>
		) {
			state.data = { ...state.data, ...action.payload };
		},
	},
});

export const { toggleRecalculateLayoutModalSliceState } = recalculateLayoutModalSlice.actions;
export default recalculateLayoutModalSlice.reducer;
