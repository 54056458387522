import Button from 'components/Basic/Button';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { addNewPanels, createNewFacets, movablePanels, removeIndividualPanels, translatePanels } from './api';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { deleteAllFacetsFromPanelDrawer, getAllKonvaGroups, resetTransformations } from 'components/tool/util';
import { useRefs } from 'contexts/RefContext';
import { transformPointsToOriginalScale } from 'components/DisplayEnergy/util';
import { batchUpdateToolState, setDeletedRoofIndexes, setRoofIndexes, setUserModificationState, 
	updateTransition } from 'store/slices/ToolSlice';
import { setAddPanel, setDeleteIndividualPanelsMode, setPanelMove, } from 'store/slices/PanelSlice';
import { useKonvaScale } from 'hooks/useKonvaScale';
import { DEFAULT_SHADING_VAL, DESIGN_MODES, KONVA_FACET_GROUP } from '../../../../constants';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { deleteObstructions } from './Facet/DeleteObstructionBtn/api';
import { toggleManualToolModalState } from 'store/slices/ManualToolModal';
import Konva from 'konva';
import { getAbsolutePointsOfTranslatedPanels } from '../util';
import { toast } from 'react-toastify';
import { toggleRecalculateLayoutModalSliceState } from 'store/slices/RecalculateLayoutSlice';

export default function CreateBtn() {

	const { newlyCreatedFacets, deletedRoofIndexes, roofIndexes, groundMountEnabled, deletedObstructionIDs } =
		useSelector((state: RootState) => state.toolNewPostions.data);
	const { newlyAddedPanels, deletedPanels, currentMovablePanels, activePanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const { uuid, panel, mode, allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const defaultMode = mode === DESIGN_MODES.DEFAULT;
	const { konvaRef } = useRefs();
	const scale = useKonvaScale();
	const dispatch = useDispatch<AppDispatch>();
	const showLoader = useQueriesRunningInBackGround(['createNewFacets', 'addPanels', 'removePanels', 'movablePanels', 'translate-panels', 
		'create-new-facets-nrel','translate-panels-nrel', 'deleteObstructions']);
	//TO_DO: refactor the condition should be same for all operation incluing panel mode
	const disableOnPartialFacet = !activePanelMode && !roofIndexes.length && !(!!Object.keys(newlyCreatedFacets).length || !!deletedRoofIndexes.length || !!deletedObstructionIDs.length);
	const disableOnPartialPanel = activePanelMode && !(newlyAddedPanels.length > 0 || deletedPanels.length > 0 || currentMovablePanels.length > 0);

	function getNewFacets() {
		if ((!Object.keys(newlyCreatedFacets).length && !deletedRoofIndexes.length)) {
			return;
		}

		const transformedFacets = transformFacets();
		return {
			transformedFacets,
			deletedRoofIndexes
		};
	}

	function transformFacets() {
		if (mode === DESIGN_MODES.NEARMAP_NREL_MANUAL) {
			return Object.values(newlyCreatedFacets).map(facet => ({
				hullCoords: facet.hullCoords.map(coord => (transformPointsToOriginalScale(scale, coord))),
				azimuthDegrees: facet.azimuthDegrees,
				pitchDegrees: facet.pitchDegrees ? +facet.pitchDegrees : groundMountEnabled ? 18 : 20,
				shading: facet.shading ? +facet.shading : DEFAULT_SHADING_VAL,
				isGroundMount: facet?.isGroundMount,
				noGapGroundMount: facet?.noGapGroundMount,
			}));
		}

		return Object.values(newlyCreatedFacets).map(facet => ({
			hullCoords: facet.hullCoords.map(coord => (transformPointsToOriginalScale(scale, coord))),
			isGroundMount: facet?.isGroundMount,
			azimuthDegrees: facet.azimuthDegrees,
			noGapGroundMount: facet?.noGapGroundMount,
		}));
	}

	function deleteFacet() {
		if (!konvaRef?.current) return;
		deleteAllFacetsFromPanelDrawer(konvaRef.current);
	}

	async function fetchUpdatedDesign(){
		await dispatch(changePanel({ uuid, panelKey: panel.key }));
	}

	function resetAllMOdifiedState(){
		dispatch(setPanelMove({ shouldEnablePanelMove: false }));
		dispatch(setAddPanel({ shouldEnable: false }));
		dispatch(updateTransition({ currentTransitionState: false }));
		dispatch(setDeletedRoofIndexes({deletedRoofIndexes:[]}));
		dispatch(setRoofIndexes({ roofIndexes: [] }));
		dispatch(setDeleteIndividualPanelsMode({enabled: false}));
		if (konvaRef?.current) {
			(konvaRef.current?.findOne('Transformer') as Konva.Transformer).setNodes([]);
			resetTransformations(getAllKonvaGroups(konvaRef.current));
		}
	}

	const createNewFacetsQuery = useQuery(
		'createNewFacets',
		async () => {
			const newFacets = getNewFacets();
			if (!newFacets) return;
			await createNewFacets({
				newPanelFacets: newFacets.transformedFacets,
				deletedRoofSegs: deletedRoofIndexes,
			}, panel.key, uuid);
			await dispatch(changePanel({ uuid, panelKey: panel.key }));
			deleteFacet();
		},
		{
			onSuccess: async () => {
				dispatch(setDeletedRoofIndexes({ deletedRoofIndexes: [] }));
				dispatch(setUserModificationState({ hasMadeChanges: true }));
			},
			onError: (error: Error) => {
				if (error.message === 'E_NO_TRANSLATIONS_TO_SAVE') return;
			},
			// retry: (failureCount, error: Error) => {
			// 	return error.message !== 'E_NO_TRANSLATIONS_TO_SAVE';
			// },
			retry: 0,
			enabled: false,
		}
	);

	const addNewPanelsQuery = useQuery(
		'addPanels',
		async () => {
			if(!konvaRef?.current || !newlyAddedPanels.length) throw new Error('E_NO_PNEL_TO_ADD');
			await addNewPanels(newlyAddedPanels, panel.key, uuid);
			await fetchUpdatedDesign();
		},
		{
			onSuccess: () => resetAllMOdifiedState(),
			onError: (error: Error) => {
				console.log(error);
			},
			retry: 0,
			enabled: false,
		}
	);

	const removePanelsQuery = useQuery(
		'removePanels',
		async () => {
			await removeIndividualPanels(deletedPanels, panel.key, uuid);
			await fetchUpdatedDesign();
		},
		{
			onSuccess: () => resetAllMOdifiedState(),
			onError: (error: Error) => {
				console.log(error);
			},
			retry: 0,
			enabled: false,
		}
	);

	const movablePanelsQuery = useQuery(
		'movablePanels',
		async () => {
			await movablePanels(currentMovablePanels, panel.key, uuid);
			await fetchUpdatedDesign();
		},
		{
			onSuccess: () => resetAllMOdifiedState(),
			onError: (error: Error) => {
				console.log(error);
			},
			retry: 0,
			enabled: false,
		}
	);

	const deleteObstructionsQuery = useQuery(
		'deleteObstructions',
		async () => {
			if (!deletedObstructionIDs?.length) return;
			await deleteObstructions(uuid, deletedObstructionIDs);
			await dispatch(changePanel({ uuid, panelKey: panel.key }));
		},
		{
			onSuccess: async () => {
				dispatch(batchUpdateToolState({
					deletedObstructionIDs: [],
					deleteObstructionsMode: false,
					userHasModifiedRoofSegs: true,
					currentActiveFacetEditMode: undefined,
					facetHistory: { currentIndex: 0, history: [] }
				}));
			},
			retry: 0,
			enabled: false,
		});

	const translatePanelPostionsQuery = useQuery(
		'translate-panels',
		async () => {
			if (!konvaRef?.current) return;
			//check any facet to delete
			if (deletedRoofIndexes.length) {
				await createNewFacets({ newPanelFacets: [], deletedRoofSegs: deletedRoofIndexes }, panel.key, uuid);
			}

			const newTranslatedPostions = getAbsolutePointsOfTranslatedPanels(konvaRef.current, roofIndexes, allRoofSegs, scale);
			if (!newTranslatedPostions.length) {
				//if the translated facet was deleted
				if (deletedRoofIndexes.length) await fetchUpdatedDesign();
				throw new Error('E_NO_TRANSLATIONS_TO_SAVE');
			}

			await translatePanels(newTranslatedPostions, panel.key, uuid);
			await fetchUpdatedDesign();
		},
		{
			onSuccess: () => resetAllMOdifiedState(),

			onError: async (error: Error) => {
				if (error.message === 'E_NO_TRANSLATIONS_TO_SAVE' || error.message === 'E_WHILE_GETTING_TRANSLATION_FOR_SEGMENT') {
					resetAllMOdifiedState();
					return;
				}
				toast.error('something went wrong, please try again');
			},
			retry: (failureCount, error: Error) => {
				return error.message !== 'E_NO_TRANSLATIONS_TO_SAVE' && failureCount < 2;
			},
			enabled: false,
		}
	);

	function beforeHandleClick(){
		let showResetLayoutMessage = false;
		const groups = konvaRef?.current?.
			find( '.' + KONVA_FACET_GROUP.EXISTING_GROUP_NAME).
			filter(g => roofIndexes.
				includes(g.attrs.id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0])) as Konva.Group[];
		groups?.forEach((g) => {
			const segmentId = g.attrs.id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0];
			const roofSegment = allRoofSegs.find(rs => rs.id === segmentId);
			const newPitch = g.getAttr('pitch');
			if((newPitch || newPitch == 0) && newPitch != roofSegment?.pitchDegrees){
				showResetLayoutMessage = true;
			}
		});
		if (showResetLayoutMessage) {
			dispatch(toggleRecalculateLayoutModalSliceState({
				open: true,
				warningMessage: 'Roof pitch changes will recalculate the layout. Proceed?',
			}));
		} else {
			handleClick();
		}
	}

	function handleClick(){
		const isNewlyAddedFacetsGroundMountInDefaultMode = defaultMode && groundMountEnabled && Object.keys(newlyCreatedFacets).length;
		const showManualModal = isNewlyAddedFacetsGroundMountInDefaultMode || (!defaultMode && Object.keys(newlyCreatedFacets).length);
		if (newlyAddedPanels.length) {
			addNewPanelsQuery.refetch();
		} else if (deletedPanels.length) {
			removePanelsQuery.refetch();
		} else if (currentMovablePanels.length) {
			movablePanelsQuery.refetch();
		} else if (deletedObstructionIDs.length) {
			deleteObstructionsQuery.refetch();
		} else if (roofIndexes.length) {
			translatePanelPostionsQuery.refetch();
		}
		else {
			if (showManualModal) {
				dispatch(toggleManualToolModalState({ value: true }));
			}
			else {
				createNewFacetsQuery.refetch();
			}
		}
	}

	return (
		<Button
			onClick={beforeHandleClick}
			style={{
				background: `${newlyCreatedFacets ? '#003CFF' : '#fff'}`,
				color: `${newlyCreatedFacets ? '#fff' : '#28373E'}`,
			}}
			showLoader={showLoader}
			disabled={!!showLoader || disableOnPartialFacet || disableOnPartialPanel}
			className='loaderbefore close-icon btn-done'
		>
			<svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8.94727 10.2716L13.2626 13.5088C13.53 13.7093 13.8632 13.8014 14.1956 13.7665C14.528 13.7316 14.8348 13.5724 15.0547 13.3207L23.5172 3.64893" stroke="#fff" strokeWidth="1.69284" strokeLinecap="round"/>
				<path d="M24.8418 12.9207C24.8418 15.4115 24.0617 17.8398 22.6108 19.8645C21.16 21.8892 19.1114 23.4085 16.7527 24.2092C14.394 25.0098 11.8438 25.0515 9.46027 24.3284C7.07669 23.6053 4.97949 22.1538 3.46322 20.1776C1.94695 18.2015 1.08777 15.8 1.00637 13.3105C0.924968 10.821 1.62543 8.3685 3.00936 6.29751C4.3933 4.22652 6.3912 2.64106 8.72244 1.7638C11.0537 0.886549 13.6012 0.761569 16.0071 1.40642" stroke="#fff" strokeWidth="1.69284" strokeLinecap="round"/>
			</svg>
			Done
		</Button>

	);

}