import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { API_ERROR_CODES, DESIGN_MODES } from '../../constants';
import { SelectorDiv, StyledSelect } from './styles';
import { errorResponseHandler } from 'store/api/AxiosError';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { useRef, useState } from 'react';
import useSwitchDesignModesQuery from 'hooks/useSwitchDesignModes';
import { setEnergyDerate, setEneryDerateToQueryParamState } from 'store/slices/QueryParamsSlice';
import { decideDerateBasedUponMode } from '../../util';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, ModalbuttonNo, Modalbutton } from 'components/OffsetModal/styles';
import Popup from 'reactjs-popup';

const options = [
	{ value: DESIGN_MODES.DEFAULT, label: 'Default' },
	{ value: DESIGN_MODES.NEARMAP_NREL_AUTOMATIC, label: 'Nearmap + NREL (Assisted Design)' },
	{ value: DESIGN_MODES.NEARMAP_NREL_MANUAL, label: 'Nearmap + NREL (Manual Design)' },
];

type Props = {
	designMode: (keyof typeof DESIGN_MODES),
	designUUID: string,
	panelKey: string,
	energyDerateRate: number,
	stateCode?: string,
}

const message = 'This will reset all your progress on the map. Are you sure you want to continue?';

export default function DesignModesSelector(props: Props) {
	const { designMode, designUUID, panelKey, energyDerateRate, stateCode } = props;

	const dispatch = useDispatch<AppDispatch>();
	const [mode, setMode] = useState<string>(designMode);
	const prevMode = useRef<string>(designMode);
	const modeToSwitchTo = useRef<string>('');

	const { mutate, isLoading } = useSwitchDesignModesQuery();
	const queriesRunningInBackGround = useQueriesRunningInBackGround();

	const { editModeEnabled, deletedRoofIndexes, drawModeEnabled, roofIndexes, deleteObstructionsMode, newlyCreatedFacets } = 
	useSelector((state: RootState) => state.toolNewPostions.data);
	const { activePanelMode } = 	useSelector((state: RootState) => state.panelSlice.data);	
	const { inputFieldsEdited } =
	useSelector((state: RootState) => state.manualToolModal.data);
	const hasUserMadeModificaiton = !!deletedRoofIndexes?.length || !!Object.keys(newlyCreatedFacets)?.length || !!roofIndexes?.length;
	const disableModeSwitch = editModeEnabled 
												&& (hasUserMadeModificaiton || activePanelMode || drawModeEnabled || deleteObstructionsMode );
	const handleSwitchMode = (userSelectedMode: DESIGN_MODES) => {
		mutate(
			{
				uuid: designUUID,
				panelKey,
				mode: userSelectedMode,
			},
			{
				onSuccess: async () => {
					if (userSelectedMode !== DESIGN_MODES.DEFAULT) {
						const modeEnergyRate = decideDerateBasedUponMode(userSelectedMode, energyDerateRate, stateCode ?? null);
						dispatch(setEnergyDerate({ energyDerate: modeEnergyRate }));
					} else {
						dispatch(setEneryDerateToQueryParamState());
					}

					await dispatch(
						changePanel({
							uuid: designUUID,
							panelKey,
							switchingSource: true,
						})
					);
					// Optionally, trigger other actions
				},
				onError: (err: AxiosError) => {
					// eslint-disable-next-line prefer-const
					let { message, error: errCode } = errorResponseHandler(err);
					if(errCode === API_ERROR_CODES.INVALID_MODE_CHECKOUT) {
						message = 'The mode you are trying to checkout isn\'t available for this address, please select a different mode';
					}
					toast.error(message);
					if (prevMode.current) setMode(prevMode.current);
					setConfirmationModal(false);
				},
			}
		);
	};

	const [confirmationModal, setConfirmationModal] = useState(false);

	const handleConfirmation = () => {
		setConfirmationModal(false);
		prevMode.current = mode;
		setMode(modeToSwitchTo.current);
		handleSwitchMode(modeToSwitchTo.current as DESIGN_MODES);
	};

	return (
		<SelectorDiv>
			<label>
				Mode
			</label>
			<StyledSelect
				isLoading={isLoading}
				isDisabled={isLoading || queriesRunningInBackGround || disableModeSwitch || inputFieldsEdited}
				options={options}
				className="custom-react-select-mode"
				classNamePrefix="cus-select"
				onChange={(data: any) => {
					if (mode === data.value) return;
					setConfirmationModal(true);
					modeToSwitchTo.current = data.value;
				}}
				value={options.find(option => option.value === mode)}
				isSearchable={false}
			/>
			<Popup
				contentStyle={{ width: '560px', borderRadius: '4px' }}
				open={confirmationModal}
				closeOnDocumentClick={false}
				onClose={() => {
					if (confirmationModal)
						setConfirmationModal(false);
				}}
			>
				<ModalPopup>
					<ModalcontentInfo>
						{message}
					</ModalcontentInfo>
			
					<ModalbuttonWrap>
						<ModalbuttonNo onClick={() => setConfirmationModal(false)}>
										No
						</ModalbuttonNo>
						<Modalbutton onClick={handleConfirmation}>
										Yes
						</Modalbutton>
					</ModalbuttonWrap>
				</ModalPopup>
			</Popup>
		</SelectorDiv>

	);
}
