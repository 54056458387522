import { memo, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { Group, Transformer } from 'react-konva';
import Konva from 'konva';
import { setRoofIndexes, setSelectedRoofIndex, updateTransition } from 'store/slices/ToolSlice';
import { useRefs } from 'contexts/RefContext';
import {
	AZIMUTH_CONSTRAINT, SEMI_TRANPARENT_BLACK_PANEL, FULL_TRANPARENT_BLACK_PANEL,
	KONVA_FACET_GROUP
} from '../../../constants';
import { normalizeAngle, resetTransformations } from '../util';
import { KonvaEventObject } from 'konva/lib/Node';
import { setDropdownState } from 'store/slices/EditFacetModalSlice';
import Facets from './Facets';
import HullCoords from './HullCoords';
import PanelsWithHull from './PanelsWithHull';

const normalizeAngleTo180 = (angle: number) => (((angle + 180) % 360) - 180);

function PanelsGroup(): JSX.Element {

	const { rasterSolarPanels: solarPanels, panel, allRoofSegs, } = useSelector((state: RootState) => state.roofData.data);
	const { 
		roofIndexes, editModeEnabled, 
		selectedRoofIndex, deletedRoofIndexes, 
		drawModeEnabled, moveStage, currentActiveFacetEditMode
	} = useSelector((state: RootState) => state.toolNewPostions.data);
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { activePanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const dispatch = useDispatch<AppDispatch>();
	const canPerformEditMoveActions = editModeEnabled && !moveStage && !drawModeEnabled;
	
	const groupRefs = useRef<Konva.Group[] | null>([]);
	const trRef = useRef<Konva.Transformer | null>(null);
	const transformTimerRef = useRef<{
		timeElapsed: number,
		timeOut?: NodeJS.Timeout
	}>({
		timeElapsed: 0
	});
	const longTouchTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);

	const { konvaRef } = useRefs();
	const solarPanelsGroupedByRoofIndex = useMemo(() => {
		groupRefs?.current && resetTransformations(groupRefs.current);
		if(editModeEnabled && trRef.current?._nodes?.[0]?.attrs?.id ){
			changePanelAlphanes(trRef.current?._nodes[0]?.attrs?.id, 'LEAVE');
		}
		groupRefs.current = [];
		trRef.current?.setNodes([]);

		const allRoofSegsObj: { [key: string]: {
			segments: RasterRoofSegment
			panels: Array<RasterSolarPanel & { userMapped:boolean }>
		} } = {};
		const panelsToSelect:any = editModeEnabled ? allRoofSegs.map(roofSeg => {
			allRoofSegsObj[roofSeg.id] = { segments: roofSeg, panels: [] };
			return roofSeg.panels;
		}).flat() : solarPanels;
		
		for (let i = 0; i < panelsToSelect.length; i++) {
			const roofIndex = panelsToSelect[i].segmentId;
			const panel= {
				...panelsToSelect[i],
				userMapped: panelsToSelect[i]?.userMapped
			};
			// mean panels are from solar panel not from allRoofSegs
			if(!allRoofSegsObj[roofIndex]) {
				allRoofSegsObj[roofIndex] = {
					segments: {} as RasterRoofSegment,
					panels: [panel]                     
				};
			} else {
				allRoofSegsObj[roofIndex].panels.push(panel);
			}
		}

		return allRoofSegsObj;
	}, [editModeEnabled, allRoofSegs, solarPanels]);

	if (currentActiveFacetEditMode || activePanelMode) {
		unselectFacet();
	}

	function addRoofIndex() {
		const roofIndex = trRef.current?.getNode()?.attrs.id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0];
		if(!activePanelMode) {
			dispatch(updateTransition({currentTransitionState: true}));
		}
		// todo check hanle this in reducer itlsef
		if (!roofIndexes.includes(roofIndex))
			dispatch(setRoofIndexes({ roofIndexes: [...roofIndexes, roofIndex] }));
	}

	function makeFacetSelectable(id: string) {
		const roofIndex = id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0];
		console.log('roofIndexselect', roofIndex, id);
		if (!roofIndex) return;
		if (roofIndex === selectedRoofIndex) return;
		dispatch(setSelectedRoofIndex({ selectedRoofIndex: roofIndex }));
	}

	const [minRotation, maxRotation] = useMemo(() => {
		const rs = allRoofSegs.find(r => r.id === selectedRoofIndex);
		const initialRotation = rs?.azimuthDegrees || 0;

		const minRotation = normalizeAngleTo180(initialRotation - AZIMUTH_CONSTRAINT);//anticlock
		const maxRotation = normalizeAngleTo180(initialRotation + AZIMUTH_CONSTRAINT);//clock

		return [minRotation, maxRotation];
	}, [allRoofSegs, selectedRoofIndex]);

	const constraintHandleRotation = useCallback((() => {
		let previousAngle = trRef.current?.getAbsoluteRotation();
		let groupId = trRef.current?.getNode()?.attrs?.id;
		return function () {
			const currentGroupId = trRef.current?.getNode()?.attrs?.id;

			if (!previousAngle || currentGroupId !== groupId) {
				previousAngle = trRef.current?.getAbsoluteRotation();
				groupId = currentGroupId;
				return;
			}

			const newAngle = trRef.current?.getAbsoluteRotation();
			if (!newAngle) return;

			const rs = allRoofSegs.find(r => r.id === currentGroupId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0]);
			const normalizedInitialAngle = normalizeAngleTo180(rs?.azimuthDegrees || 0);
			const isInNegativeQuadrant = Math.abs(normalizedInitialAngle) > 90;
	
			if (isInNegativeQuadrant) {
				const normalizedNewAngle = normalizeAngle(newAngle);
				const normalizedPreviousAngle = normalizeAngle(previousAngle);
				const normalizedMaxRotaionAngle = normalizeAngle(maxRotation);
				const normalizedMinRotaionAngle = normalizeAngle(minRotation);

				const userIsRotatingFromLeftToRight = normalizedNewAngle > normalizedPreviousAngle && normalizedNewAngle < normalizedMaxRotaionAngle;
				const userIsRotatingFromRightToLeft = normalizedNewAngle < normalizedPreviousAngle && normalizedNewAngle > normalizedMinRotaionAngle;

				if (userIsRotatingFromLeftToRight || userIsRotatingFromRightToLeft) {
					previousAngle = newAngle;
					return;
				}

				if (normalizedNewAngle > normalizedMaxRotaionAngle || normalizedNewAngle < normalizedMinRotaionAngle) {
					trRef.current?.stopTransform();
					previousAngle = newAngle;
					return;
				}
			} else {
				const userIsRotatingFromLeftToRight = newAngle > previousAngle && newAngle < maxRotation;
				const userIsRotatingFromRightToLeft = newAngle > minRotation && newAngle < previousAngle;

				if (userIsRotatingFromLeftToRight || userIsRotatingFromRightToLeft) {
					previousAngle = newAngle;
					return;
				}

				if (newAngle > maxRotation || newAngle < minRotation) {
					trRef.current?.stopTransform();
					previousAngle = newAngle;
					return;
				}
			}
		};
	})(), [maxRotation, minRotation]);

	function unselectFacet() {
		if (!trRef.current?._nodes || !konvaRef?.current) return;
		if (trRef.current?._nodes[0]?.attrs.id) {
			const id = trRef.current._nodes[0].attrs.id;
			konvaRef.current.container().style.cursor = 'default';
			groupRefs.current && resetTransformations(groupRefs.current);
			changePanelAlphanes(id, 'LEAVE');
			toggleFacetHighlight(id, 'LEAVE');
			trRef.current.getNode()?.draggable(false);
			trRef.current.detach();
			makeFacetSelectable(id);
		}
	}

	function makeFacetTranslative(id: string) {
		if(activePanelMode) {
			return;
		}
		// trRef.current?.getNode()?.draggable(false);
		trRef.current?.detach();
		const clickedGroup = konvaRef?.current?.findOne('#' + id) as Konva.Group | undefined;
		const rs = allRoofSegs.find(r => r.id === id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0]);
		if(!rs || !clickedGroup) return;
		//diable drag for grp having no panel array
		if(clickedGroup?.getChildren()?.length === 1){
			clickedGroup?.draggable(false);
			return;
		}
		clickedGroup?.draggable(true);

		if (!clickedGroup.rotation()) {
			clickedGroup?.rotation(rs.azimuthDegrees);
			clickedGroup?.getChildren().forEach(e => e.rotation(-rs.azimuthDegrees));
		}

		trRef.current?.setNodes([clickedGroup]);
	}

	const handleGroupClick = useCallback((() => {
		let previousSelectedGroupId: string;
		return function (id: string) {
			if(showIrradiance) return;
			console.log('id', id);
			if (!previousSelectedGroupId) {
				previousSelectedGroupId = id;
				changePanelAlphanes(id, 'ENTER');
				toggleFacetHighlight(id, 'ENTER');
				makeFacetTranslative(id);
				makeFacetSelectable(id);
				return;
			}

			if (previousSelectedGroupId !== id) {
				changePanelAlphanes(previousSelectedGroupId, 'LEAVE');
				changePanelAlphanes(id, 'ENTER');
				toggleFacetHighlight(previousSelectedGroupId, 'LEAVE');
				toggleFacetHighlight(id, 'ENTER');
				makeFacetTranslative(id);
				makeFacetSelectable(id);
				previousSelectedGroupId = id;
				return;
			}
			if (previousSelectedGroupId == id) {
				changePanelAlphanes(id, 'ENTER');
				toggleFacetHighlight(id, 'ENTER');
				makeFacetTranslative(id);
				makeFacetSelectable(id);
				return;
			}
		};
	})(), [editModeEnabled, allRoofSegs, activePanelMode, konvaRef]);

	function changePanelAlphanes(id: string, action: 'ENTER' | 'LEAVE') {
		const panelObjs = groupRefs.current?.find(g => g.attrs.id === id);
		if ( !panelObjs || !panelObjs?.children?.length) return;
		const lines = panelObjs?.children as Konva.Line[];
		for (let i = 1; i < lines.length; i++) {
			lines[i].attrs['fill'] = action === 'ENTER' ? FULL_TRANPARENT_BLACK_PANEL : SEMI_TRANPARENT_BLACK_PANEL;
		}
	}

	function toggleFacetHighlight(id: string, action: 'ENTER' | 'LEAVE') {
		const group = konvaRef?.current?.findOne('#' + id) as Konva.Group | undefined;
		if ( !group?.children?.length) return;
		const lines = group?.children as Konva.Line[];
		if (lines[0] && lines[0]?.hasName('movable-facet')) {
			lines[0].visible(action === 'LEAVE' || lines.length === 1);
			lines[0].attrs['fill'] = action === 'ENTER' ? 'rgba(255, 255, 0, 0.3)' : 'transparent';
		}
	}

	function handleTouchStart(e: KonvaEventObject<TouchEvent>) {
		if (!editModeEnabled || activePanelMode) return;
		clearTimeout(longTouchTimerRef.current);
		longTouchTimerRef.current = setTimeout(() => {
			openEditFacetPopup({ x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY });
		}, 2000);
	}

	function handleTouchEndOrCancel() {
		clearTimeout(longTouchTimerRef.current);
	}

	function handleContextMenuEvt(e: KonvaEventObject<MouseEvent>, name: string) {
		if (!canPerformEditMoveActions || activePanelMode) return;
		e.evt.preventDefault();
		console.log({ x: e.evt.clientX, y: e.evt.clientY }, 'pos');
		handleGroupClick(name);
		openEditFacetPopup({ x: e.evt.clientX, y: e.evt.clientY });
	}

	function openEditFacetPopup(position: Vector2d) {
		dispatch(setDropdownState({ open: true, position }));
	}

	function handleDragStart(id: string){
		if (!canPerformEditMoveActions || activePanelMode) return;
		if (konvaRef?.current) {
			handleGroupClick(id);
			clearTimeout(longTouchTimerRef.current);
			konvaRef.current.container().style.cursor = 'move';
		}
	}

	return (
		<>
			{
				Object.entries(solarPanelsGroupedByRoofIndex).map((ob) => {
					if (deletedRoofIndexes.includes(ob[0])) return null;
					const groupId = ob[0] + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.EXISTING_GROUP_NAME;
					return (
						<Group
							key={ob[0] + panel.key}
							id={groupId}
							ref={(el) => {
								if (el && !groupRefs.current?.includes(el)) {
									groupRefs.current?.push(el);
								}
							}}
							listening= {!currentActiveFacetEditMode}
							name={KONVA_FACET_GROUP.EXISTING_GROUP_NAME}
							onContextMenu={(e)=>handleContextMenuEvt(e, groupId) }
							onClick={(e) => {
								if (!canPerformEditMoveActions || activePanelMode || showIrradiance) return;
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'move';
								handleGroupClick(groupId);
							}}
							draggable={editModeEnabled && !drawModeEnabled && !moveStage && !activePanelMode && !showIrradiance}
							onTap={() => {
								if (!canPerformEditMoveActions || activePanelMode) return;
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'move';
								handleGroupClick(groupId);
							}}
							onTouchStart={handleTouchStart}
							onTouchEnd={handleTouchEndOrCancel}
							onMouseLeave={() => {
								if (!canPerformEditMoveActions) return;
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'default';
							}}
							onDragStart={() => handleDragStart(groupId)}
							onDragEnd={() => {
								if (!canPerformEditMoveActions || activePanelMode) return;
								addRoofIndex();
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'default';
							}}
						>
						
							<PanelsWithHull panelsObj={ob}/>
						</Group>
					);
				})
			}
			{
				(groupRefs.current) &&
				<Transformer
					ref={trRef}
					anchorSize={20}
					resizeEnabled={false}
					borderEnabled={true}
					rotateAnchorOffset={25}
					borderStroke={'rgba(255, 147, 43, 0.80)'}
					anchorStroke={'rgba(255, 147, 43, 0.80)'}
					anchorFill={'rgba(255, 147, 43, 0.80)'}
					borderStrokeWidth={2}
					onTranformStart={() => {
						transformTimerRef.current.timeElapsed = performance.now();
						clearTimeout(transformTimerRef.current.timeOut);
					}}
					onTransform={constraintHandleRotation}
					onTransformEnd={() => {
						addRoofIndex();
						transformTimerRef.current.timeElapsed = performance.now() - transformTimerRef.current.timeElapsed;
						queueMicrotask(() => {
							transformTimerRef.current.timeOut = setTimeout(() => {
								transformTimerRef.current.timeElapsed = 0;
							}, 200);
						});
					}}
				/>
			}

			{
				solarPanelsGroupedByRoofIndex &&
				<Facets/>
			}
			{
				<HullCoords/>
			}
		</>
	);
}
export default memo(PanelsGroup);