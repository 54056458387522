import { useDispatch } from 'react-redux';
import PitchSlider from './PitchSlider';
import { DivPanelCount, AzimuthHeading, DivPitchWrap, DivFacetDetail, ShadingWrap } from './style';
import { AppDispatch } from 'store';
import { toggleManualToolDoneButtonState, toggleManualToolResetButtonState } from 'store/slices/ManualToolModal';
import { DEFAULT_SHADING_VAL, 
	GROUND_MOUNT_DEFAULT_PITCH, 
	KONVA_FACET_GROUP,
	NORMAL_FACET_DEFAULT_PITCH } from '../../constants';
import { ToggleButton, ToggleGroup } from 'components/Basic/ToggleGroup';

function AzimuthDiv(props: { azimuthDegrees: number }) {
	const roundedValue = Math.round(props.azimuthDegrees);
	return (
		<AzimuthHeading>
			Azimuth:
			<span>{roundedValue}</span>
		</AzimuthHeading>
	);
}

function PitchDiv(props: { pitchDegrees: number }) {
	const roundedValue = Math.round(props.pitchDegrees);
	return (
		<DivPitchWrap>
			Pitch:
			<span>{roundedValue}</span>
		</DivPitchWrap>
	);
}
function PanelsCountDiv(props: { panelsCount: number }) {
	return (

		<DivPanelCount>
			Panels Count:
			<span>{props.panelsCount} </span>
		</DivPanelCount >
	);
}

function SystemProductionDiv(props: { systemProduction: number }) {
	const roundedValue = Math.round(+props.systemProduction.toFixed(2));
	return (
		<DivPanelCount>
			System Production:
			<span>{roundedValue} kWh</span>
		</DivPanelCount>
	);
}

function SystemSize(props: { systemSize: number }) {
	const roundedValue = +props.systemSize.toFixed(2);
	return (
		<DivPanelCount>
			System Size:
			<span>{roundedValue} kW</span>
		</DivPanelCount>
	);
}

type Props = {
	groupSuffix: string;
	konvaPolygonId: string;
	active?: boolean;
	onChange?: (id: string, groupSuffix: string) => void;
	azimuthDegrees?: number;
	pitchDegrees?: number;
	systemSize?: number;
	systemProduction?: number;
	panelsCount?: number;
	previousPitch?: number;
	isGroundMount? : boolean;
	shading? : number;
	onPitchValChange?: (id: string, groupSuffix: string, value: any) => void;
	onShadingValChange?: (id: string, groupSuffix: string, value: any)=> void;
};
export default function DefaultModeValues(props: Props) {
	const { azimuthDegrees, pitchDegrees, shading, isGroundMount, groupSuffix, systemProduction, konvaPolygonId, systemSize, panelsCount } = props;
	const dispatch = useDispatch<AppDispatch>();
	function handleClick() {
		props.onChange && props.onChange(konvaPolygonId, groupSuffix);
	}

	return (
		<>
			{
				<DivFacetDetail className={` ${props.active ? ' active' : ''}`} onClick={handleClick}>
					<AzimuthDiv azimuthDegrees={azimuthDegrees!} />
					{(isGroundMount) ? 
						<div className='facet-slider-wrap facet-slider-slope-info'>
							{
								<PitchSlider 
									pitch={pitchDegrees ?? GROUND_MOUNT_DEFAULT_PITCH}
									active= {!!props.active }
									onChange={(v) => {
										props.onPitchValChange && props.onPitchValChange(konvaPolygonId, groupSuffix, +v);
										props.onChange && props.onChange(konvaPolygonId, groupSuffix);
										dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: true }));
										if(groupSuffix.slice(1) != KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME){
											dispatch(toggleManualToolResetButtonState({resetEnabled: true}));
										}
									}}
								/>
							}
						</div> : <PitchDiv pitchDegrees={pitchDegrees ?? NORMAL_FACET_DEFAULT_PITCH} />}
					{(isGroundMount) &&
					<ShadingWrap className='facet-slope-wrap facet-slider-slope-info'>
						<span>Shading:</span>
						<ToggleGroup
							defaultValue={shading ? shading.toString() : DEFAULT_SHADING_VAL.toString()}
							onChange={(v) => {
								props.onShadingValChange && props.onShadingValChange(konvaPolygonId, groupSuffix, +v);
								props.onChange && props.onChange(konvaPolygonId, groupSuffix);
								dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: true }));
							}}
						>
							<ToggleButton value="14.08">None</ToggleButton>
							<ToggleButton value="15.85">low</ToggleButton>
							<ToggleButton value="20.28">medium</ToggleButton>
							<ToggleButton value="24.76">high</ToggleButton>
						</ToggleGroup>
					</ShadingWrap>
					}
					<SystemProductionDiv systemProduction={systemProduction ?? 0} />
					<SystemSize systemSize={systemSize ?? 0} />
					<PanelsCountDiv panelsCount={panelsCount ?? 0} />
				</DivFacetDetail>
			}
		</>
	);

}